import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import JotformEmbed from 'react-jotform-embed'
import styled from "styled-components";
import ImageCarousel from "../components/common/ImageCarousel"; // Adjust the import
import { graphql, Link } from "gatsby";


import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const StyledH2 = styled.h2`
  font-weight: 600;
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 19px;
  }
`

const PerrisPage = ({ data }) => {
  const [carouselIsOpen, setCarouselIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openCarousel = (index) => {
    setCarouselIsOpen(true);
    setSelectedImageIndex(index);
  };

  const closeCarousel = () => {
    setCarouselIsOpen(false);
    setSelectedImageIndex(0);
  };

  const images = data.allFile.nodes.map((node) => node.childImageSharp.gatsbyImageData);

  return (
    <Layout>
      <Seo title="Perris" />

      <div className="bg-white w-full 2xl:max-w-screen-xl mx-auto">
        <div className="w-full mx-auto pb-5 px-6 xl:px-20">
          <div className="relative">
            <GatsbyImage
              alt="Perris Hero Background"
              image={getImage(data.heroImage.childImageSharp.gatsbyImageData)}
              className="z-0"
            />
            
            <h1 className="main-title">Perris</h1>
          </div>
        </div>

        <div className="w-full flex flex-col items-center mx-auto pb-16 px-6 xl:px-20">
          <div className="w-full mx-auto">
            <StyledH2 className="text-site-green mb-3">Wide Selection of Beautiful Plants</StyledH2>

            <p className="font-body text-base italic font-bold mb-3">“Our Hot Weather Champions”</p>

            <p className="font-body text-base mb-3">The area surrounding the city of Perris has a semi-desert climate, which is an ideal location to grow more of our drought resistant and heat-tolerant plant material. Located in Riverside County where summers are warm to hot and winters are cool, the plants grown in this area have become accustomed to the hot temperatures, allowing us to ship heat-tolerant and drought-resistant plant material to unforgiving desert cities like Las Vegas and Palm Springs.</p>

            <p className="font-body text-base">At the Perris location, we are able to carry native specimens like the California Pepper and California Oak. A few more samples of the plants that thrive in the extreme heat of Perris yard are: Acacia Lowboy, Euonymus microphylla, Euonymus japonica, Italian Cypress, Ligustrum texanum, Ligustrum japonicum, Myoporum parvifolium ‘Putah Creek’, and Palms chamaerops humulis.</p>

            <Link to="/inventory">
              <button className="bg-white hover:bg-site-green-dark text-site-green-dark hover:text-white text-base font-medium text-center px-6 py-2 border border-site-green-dark mt-9">
                View Our Full Inventory
              </button>
            </Link>

            <div className="w-full bg-site-green-dark py-2 mt-5 mb-8">
              <h2 className="text-white text-lg font-semibold text-center uppercase">Photo Gallery</h2>
            </div>

            <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-6 mx-auto">
              {images.map((image, index) => (
                <div key={index} onClick={() => openCarousel(index)}>
                  <GatsbyImage
                    alt={`Perris ${index + 1}`}
                    image={image}
                    className="w-full h-full object-cover cursor-pointer"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="w-full mx-auto border mt-12">
            <JotformEmbed src="https://form.jotform.com/233212775091453" />
          </div>
        </div>
      </div>

      {/* Conditionally render ImageCarousel */}
      {carouselIsOpen && (
        <ImageCarousel
          isOpen={carouselIsOpen}
          closeCarousel={closeCarousel}
          images={images}
          selectedImageIndex={selectedImageIndex}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "backgrounds/banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1180
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allFile(filter: { relativePath: { regex: "/perris/perris-/" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 800
            height: 800
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;

export default PerrisPage
